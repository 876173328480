import { functions } from '@wap-client/core';

import { UISearchPanelInput } from './types';
import { Column, Container, Row } from '@/components/base/gridview';

const SearchPanelInput: React.FC<UISearchPanelInput> = ({
  searchValue,
  setSearchValue,
  setIsFocus,
  onSubmit,
  isFocus,
  resultLenght,
  input,
}) => {
  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleBlur = () => {
    if (searchValue.length === 0) {
      setIsFocus(false);
    }
  };

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  return (
    <Container size="wide">
      <Row>
        <Column>
          <form
            className={functions.classnames(
              'search-panel-input',
              (isFocus || searchValue.length > 0) &&
                'search-panel-input--focus',
              resultLenght && resultLenght > 0 && 'search-panel-input--results'
            )}
            onSubmit={(e) => {
              onSubmit(e);
            }}
          >
            <input
              onChange={handleChange}
              onClick={handleFocus}
              onBlur={handleBlur}
              type="text"
              value={searchValue}
              placeholder="Search for something…"
              ref={input}
            />

            <button
              type="submit"
              className="search-panel-input-submit"
              style={{ display: isFocus ? 'block' : 'none' }}
            >
              Search
            </button>
            <label className="search-panel-input-label">
              Example: Ski resorts, beaches, historical places
            </label>
          </form>
        </Column>
      </Row>
    </Container>
  );
};

export default SearchPanelInput;
