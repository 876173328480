import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export interface IUserData {
  sub: string;
  given_name: string;
  family_name: string;
  name: string;
  username: string;
  language: string;
  email: string;
  email_verified: boolean;
  gender: number;
  allowCommunication: boolean;
  allowNewsletter: boolean;
  allowWhatsAppCommunication: boolean;
  birthDate: string;
  city: string;
  cityId: string;
  country: string;
  countryId: string;
  phoneNumber: string;
  middlename: string;
  isNomandUser: boolean;
}
interface IAuthData {
  isAuth?: boolean;
  token: string;
  refreshToken: string;
  user?: IUserData;
}
interface AuthState {
  authData: IAuthData;
  setAuthData: (user: IAuthData) => void;
  setAuthUserData: (user: IUserData) => void;
  logoutUser: () => void;
}

const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        authData: { token: '', isAuth: false } as IAuthData,
        setAuthData: (data: IAuthData) =>
          set((state) => ({
            ...state,
            authData: { ...data, isAuth: data.token ? true : false },
          })),
        setAuthUserData: (user: IUserData) =>
          set((state) => ({
            ...state,
            authData: {
              ...state.authData,
              user: {
                ...state.authData.user,
                ...user,
              },
            },
          })),
        logoutUser: () =>
          set((state) => ({
            ...state,
            authData: { token: '', isAuth: false } as IAuthData,
          })),
      }),
      { name: 'authStore' }
    )
  )
);

export default useAuthStore;
