import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { functions, useApp } from '@wap-client/core';
import useDeviceType from '@/hooks/useDeviceType';

import Anchor from '@/components/base/anchor';
import Icon from '@/components/base/icon';
import Button from '@/components/base/button/Button';
import Image from '@/components/base/image';
import { Column, Container, Row } from '@/components/base/gridview';

import MotivationBox from '../../motivation-box';
import BuildRouteBox from '../../build-route-box/BuildRouteBox';
import SearchPanelInput from './input/Input';
import Discover from '../../discover';
import CustomButton from '../../custom-button';
import Headline from '../../headline/Headline';

import { getAssets, getRegions } from './SearchHelper';

import { UIRegion } from '../../discover/types';
import { UISearchAsset, UISearchPanel } from './types';

import NoRecords from '@/components/base/no-records';
import { kebabToTitleCase } from '@/utils';

const SearchPanel: React.FC<UISearchPanel> = ({
  isShowSearchPanel,
  handleSearchPanel,
  recommendations,
  isSearchPanelDelay,
  citiesData = [],
  regionsData = [],
}) => {
  const deviceType = useDeviceType();
  const app = useApp();

  const input = useRef<HTMLInputElement>(null);

  const [searchValue, setSearchValue] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  const [cities, setCities] = useState<UISearchAsset[]>([]);
  const [assets, setAssets] = useState<UISearchAsset[]>([]);
  const [routes, setRoutes] = useState<UISearchAsset[]>([]);

  const [results, setResults] = useState<UISearchAsset[]>([]);

  useEffect(() => {
    const fetchAssets = async () => {
      const { cities, assets, routes } = await getAssets(
        searchValue,
        app.environment,
        app.language
      );
      setCities(cities);
      setAssets(assets);
      setRoutes(routes);
    };

    if (searchValue.length % 3 === 0) {
      fetchAssets();
    }

    if (searchValue.length === 0) {
      setResults([]);
    }
  }, [searchValue]);

  useLayoutEffect(() => {
    if (isShowSearchPanel === true) {
      document.body.classList.add('disabled-scroll');
    }

    return () => {
      document.body.classList.remove('disabled-scroll');
    };
  }, [isShowSearchPanel]);

  const renderRecommendations = () => {
    return (
      <nav className="search-panel-recommendations-nav">
        {searchValue.length === 0 ? (
          <ul>
            {recommendations?.map((item, index) => (
              <li key={index}>
                <Anchor {...item}>{item.title}</Anchor>
                {item.children && (
                  <ul>
                    {item.children.map((subItem, index) => (
                      <li key={index}>
                        <Anchor {...item}>{subItem.title}</Anchor>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        ) : cities.length || assets.length || routes.length ? (
          <ul>
            {cities.length > 0 && (
              <li>
                <Anchor {...app.settings.routes['where-to-go']}>
                  Where To Go
                </Anchor>
                <ul>
                  {cities.slice(0, 3).map((city, index) => (
                    <li key={index}>
                      <Anchor href={city.anchor?.path}>
                        {city.title}
                        {city.anchor?.path}
                        <span>
                          {city.breadcrumb &&
                            city.breadcrumb.href &&
                            kebabToTitleCase(city.breadcrumb?.href)}
                        </span>
                      </Anchor>
                    </li>
                  ))}
                </ul>
              </li>
            )}

            {assets.length > 0 && (
              <li>
                <Anchor {...app.settings.routes['things-to-do']}>
                  Things To Do
                </Anchor>
                <ul>
                  {assets.slice(0, 3).map((asset, index) => (
                    <li key={index}>
                      <Anchor href={asset.anchor?.path}>
                        {asset.title}
                        <span>
                          {asset.breadcrumb &&
                            asset.breadcrumb.href &&
                            kebabToTitleCase(asset.breadcrumb?.href)}
                        </span>
                      </Anchor>
                    </li>
                  ))}
                </ul>
              </li>
            )}

            {routes.length > 0 && (
              <li>
                <Anchor href="">Routes for you</Anchor>
                <ul>
                  {routes.slice(0, 3).map((route, index) => (
                    <li key={index}>
                      <Anchor href={route.anchor?.path}>
                        {route.title}
                        <span>Route</span>
                      </Anchor>
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        ) : (
          <NoRecords display={2} icon={true} />
        )}
      </nav>
    );
  };

  const handleClear = () => {
    setSearchValue('');
    setResults([]);
    setIsFocus(false);
    handleSearchPanel();
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const fetchResult = async () => {
      const { combined } = await getAssets(
        searchValue,
        app.environment,
        app.language
      );

      setResults(combined);
    };

    fetchResult();
    input.current?.blur();
  };

  return (
    <div
      className={functions.classnames(
        'search-panel',
        isSearchPanelDelay ? 'search-panel-backwards' : ''
      )}
    >
      <Button
        className="search-panel-close"
        id="search-panel-close"
        onClick={() => handleClear()}
      >
        <Icon name="icon-close" size={'small'} />
      </Button>
      <div className="search-panel-in">
        <div className="search-panel-header">
          <SearchPanelInput
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            handleSearchPanel={handleSearchPanel}
            setIsFocus={setIsFocus}
            isFocus={isFocus}
            onSubmit={onSubmit}
            resultLenght={results.length}
            input={input}
          />
        </div>

        <div
          className={functions.classnames(
            'search-panel-recommendations',
            isFocus &&
              results.length === 0 &&
              'search-panel-recommendations--show'
          )}
        >
          <Container size="wide">
            <Row>
              <Column>
                <div>{renderRecommendations()}</div>
              </Column>
            </Row>
          </Container>
        </div>

        <div
          className={functions.classnames(
            'search-panel-results',
            searchValue.length > 0 &&
              results.length > 0 &&
              'search-panel-results--show'
          )}
        >
          <Container size="wide">
            <Row>
              <Column>
                {searchValue.length > 0 && (
                  <div className="search-panel-results-title">
                    <div className="search-panel-results-title-count">
                      {results.length}{' '}
                      {app.settings.translations['results-for']} "{searchValue}"
                    </div>

                    <CustomButton
                      className="search-panel-results-title-filter"
                      iconBefore={<Icon name="icon-menu-circle-outline" />}
                    >
                      {app.settings.translations['filter']}
                    </CustomButton>
                  </div>
                )}

                <div className="search-panel-results-items">
                  {results.map((result, index) => (
                    <div key={index}>
                      <Anchor
                        className="search-panel-results-items-item"
                        href={result.anchor?.path}
                      >
                        {(deviceType === 'desktop' ||
                          deviceType === 'laptop') && (
                          <div className="search-panel-results-items-item-thumbnail">
                            <Image {...result.image} alt={result.title} />
                          </div>
                        )}

                        <div className="search-panel-results-items-item-desc">
                          <h3>
                            {result.title}
                            <span>
                              {result.breadcrumb && result.breadcrumb.href && (
                                <Anchor href={result.breadcrumb?.href}>
                                  {kebabToTitleCase(result.breadcrumb?.href)}
                                </Anchor>
                              )}
                            </span>
                          </h3>
                          {result.description && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: result.description,
                              }}
                            />
                          )}
                        </div>
                      </Anchor>
                    </div>
                  ))}
                </div>
              </Column>
            </Row>
          </Container>
        </div>

        {(deviceType === 'desktop' || deviceType === 'laptop') && (
          <div
            className={functions.classnames(
              'search-panel-content',
              (searchValue.length > 0 || isFocus) &&
                'search-panel-content--hidden'
            )}
          >
            <Container size="wide">
              <Row>
                <Column xs={{ size: 6 }} style={{ padding: 0 }}>
                  <div className="search-panel-content-map">
                    <Headline
                      title={{
                        text: app.settings.translations['discoverSubTitle'],
                        tag: 'h4',
                      }}
                      subTitle={{
                        text: app.settings.translations['discoverTitle'],
                        tag: 'h3',
                      }}
                    />
                    <Discover
                      isMobileMenu={true}
                      showList={false}
                      regions={regionsData}
                      cities={citiesData}
                    />
                  </div>
                </Column>
                <Column xs={{ size: 6 }} className="search-panel-content-boxes">
                  <MotivationBox />
                  <BuildRouteBox />
                </Column>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPanel;
