import React, { useCallback, useEffect, useState } from 'react';
import { IHeaderProps } from './types';
import { Container } from '@/components/base/gridview';
import Anchor from '@/components/base/anchor';
import Icon from '@/components/base/icon';
import { functions, useApp } from '@wap-client/core';
import MobilePanel from '@/components/widgets/header/mobile-panel';
import PanelContextProvider from '@/context/panel-context-provider';
import { usePanelContext } from '@/hooks/usePanelContext';
import SearchPanel from './search-panel';
import useDeviceType from '@/hooks/useDeviceType';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import useAuthStore from '@/context/useAuth';
import { NodeEnv } from '@wap-client/constants';
import { getUserCheckInfo } from '@/services/goturkiye';
import { path } from '@wap-client/core';
import Image from 'next/image';

const Header: React.FC<IHeaderProps> = ({
  logo,
  navigation,
  searchPanel,
  regions = [],
  cities = [],
}) => {
  const app = useApp();
  const [isShowSearchPanel, setIsShowSearchPanel] = useState<boolean>(false);
  const [isSearchPanelDelay, setIsSearchPanelDelay] = useState<boolean>(false);
  const [scrolled, setIsScrolled] = useState<boolean>(false);
  const deviceType = useDeviceType();
  const imgUrl = path.asset(app.environment, logo?.src) || '';

  const handleSearchPanel = () => {
    if (isShowSearchPanel && !isSearchPanelDelay) {
      setIsSearchPanelDelay(true);

      setTimeout(() => {
        setIsShowSearchPanel(false);
      }, 1000);
    } else {
      setIsShowSearchPanel(true);
      setIsSearchPanelDelay(false);
    }
  };

  const handleBuildRoute = () => {
    const route = document.querySelector('.build-routes');
    if (route) {
      route.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const IntermediateElement: React.FC<IHeaderProps> = () => {
    const { dispatch } = usePanelContext();

    const handleMobileMenu = () => {
      dispatch({
        type: 'CHANGE',
        payload: { isShowMobilMenu: true, routeHistory: [''] },
      });
    };

    return (
      <button className="header-actions-btn menu" onClick={handleMobileMenu}>
        <Icon name="icon-hamburger-menu" />
      </button>
    );
  };

  type Callback = (...args: any[]) => void;

  const debounce = <T extends Callback>(fn: T, delay: number): T => {
    let timerId: ReturnType<typeof setTimeout> | undefined;
    return ((...args: Parameters<T>) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => fn(...args), delay);
    }) as T;
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (
        window.scrollY > 100 &&
        (deviceType === 'desktop' || deviceType === 'laptop')
      ) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }, 30);

    if (deviceType !== 'mobile') {
      document.addEventListener('scroll', handleScroll);
    }

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [deviceType]);

  return (
    <PanelContextProvider>
      <section
        className={functions.classnames(
          'header-layout',
          scrolled && 'scrolled'
        )}
      >
        <Container className="header">
          {logo && (
            <Anchor className="header-logo" rootRoute>
              <Image
                src={imgUrl}
                width={115}
                height={40}
                alt="Türkiye logo icon"
              />
            </Anchor>
          )}

          <nav>
            <ul>
              {navigation &&
                navigation.map((navItem, index) => (
                  <motion.li key={index}>
                    <Anchor {...navItem}>{navItem.title}</Anchor>
                  </motion.li>
                ))}
            </ul>
          </nav>

          <div className="header-actions">
            <button
              className="header-actions-btn search"
              onClick={handleSearchPanel}
            >
              <Icon name="icon-search" />
            </button>
            <button
              id="qa-panel-open-btn"
              className="header-actions-btn route"
              onClick={handleBuildRoute}
            >
              <Icon name="icon-build-route" />
            </button>
            <HeaderUserButton />
            <IntermediateElement />
          </div>
        </Container>
        <MobilePanel />

        {isShowSearchPanel && (
          <SearchPanel
            navigation={searchPanel?.navigation}
            recommendations={searchPanel?.recommendations}
            handleSearchPanel={handleSearchPanel}
            isShowSearchPanel={isShowSearchPanel}
            setIsShowSearchPanel={setIsShowSearchPanel}
            isSearchPanelDelay={isSearchPanelDelay}
            citiesData={cities}
            regionsData={regions}
          />
        )}
      </section>
    </PanelContextProvider>
  );
};

export const HeaderUserButton = () => {
  const { authData, logoutUser } = useAuthStore();
  const app = useApp();

  const router = useRouter();
  const userName = authData?.user?.given_name?.slice(0, 2).toUpperCase() || '';

  const onUserAction = useCallback(() => {
    const baseUrl =
      process.env.NEXT_PUBLIC_ENV === NodeEnv.Production
        ? '/'
        : `/${app.environment.key}/`;
    const profileHref = app.settings.routes['my-profile'];
    if (authData.isAuth && profileHref?.href) {
      router.push(`${baseUrl}${profileHref.href}`);
    } else {
      router.push('/auth/sign-in');
    }
  }, [authData]);

  useEffect(() => {
    getUserCheckInfo(false, () => {
      logoutUser();
    });
  }, []);

  return (
    <button
      className={functions.classnames(
        'header-actions-user-btn',
        authData?.isAuth && 'header-user-avatar'
      )}
      onClick={() => onUserAction()}
    >
      {authData?.isAuth ? <p>{userName}</p> : <Icon name="icon-user-circle" />}
    </button>
  );
};
export default Header;
